import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const MockTestDescription = () => {
  const navigate = useNavigate();
  const audioRef = useRef(new Audio(`${process.env.REACT_APP_S3_BASE_URL}/recordings/mocktest-description.mp3`));  // Placeholder for audio file

  const handleStartTest = () => {
    if (audioRef.current) {
      audioRef.current.pause();  // Stop the audio when starting the test
    }
    navigate('/mocktest1');  // Navigate to the mock test page
  };

  // Play the audio on component mount
  React.useEffect(() => {
    if (audioRef.current) {
      audioRef.current.play().catch((err) => console.error('Audio failed to play:', err));
    }

    return () => {
      // Stop the audio when the component unmounts
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    };
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-4">
      <h1 className="text-2xl font-bold text-center mb-8">
        배토스 토익 스피킹 1회 모의고사 안내
      </h1>

      {/* Main Description */}
      <div className="text-base lg:text-lg mb-8 w-full max-w-4xl mx-auto">
        <p className="mb-4">
          모의고사는 약 30분 정도 소요되며, 크롬 브라우저에서 데스크탑을 사용하여 응시하는 것을 권장합니다. 
        </p>
        <p className="mb-4">
        조용한 환경에서 음성을 인식할 수 있도록 준비해주세요. 시험 도중에는 멈출 수 없으며, 10 크레딧이 차감됩니다.
        </p>
        {/* <p className="mb-4">
          모의고사는 총 5개 파트로 나누어져 있으며, 각 파트는 준비 시간과 답변 시간이 다릅니다. 각 문제에 대한 준비 시간 후에 답변을 녹음하고
          제출해주시면 됩니다. 모든 파트가 끝난 후, 모의고사가 종료됩니다.
        </p> */}
      </div>

      {/* Tips Section */}
      <div className="border-2 border-[#A1824A] p-6 rounded-lg w-full max-w-4xl mx-auto">
        <ul className="list-disc list-inside mb-4 text-base lg:text-lg">
            <li className='mb-4'>
            <strong>Part 1:</strong> 주어진 문단을 읽는 문제로, 준비시간 45초와 답변시간 45초가 주어집니다. 이 시간 동안 발음, 억양, 강세를 모두 신경 써서 연습하는 것이 중요합니다. 특히 읽기 어려운 단어들을 미리 반복적으로 연습해보세요.
            </li>
            <li className='mb-4'>
            <strong>Part 2:</strong> 제시된 사진을 묘사하는 문제로, 준비시간 45초와 답변시간 30초가 주어집니다. 준비시간 동안 사진 속의 대상, 동작 등을 빠르게 정리하고 노트에 간단히 메모해 두는 것이 도움이 됩니다.
            </li>
            <li className='mb-4'>
            <strong>Part 3:</strong> 안내문에 관한 3개의 질문에 답하는 문제로, 각 질문에 대해 3초의 준비시간이 주어지며, 답변시간은 각각 15초, 15초, 30초입니다. 주어진 질문에 맞는 문장 구조를 활용하여 간결하고 명확하게 답변해보세요.
            </li>
            <li className='mb-4'>
            <strong>Part 4:</strong> 제시된 정보를 참고하여 문제에 답하는 형식입니다. 준비시간 45초와 각각 15초, 15초, 30초의 답변시간이 주어집니다. 제시된 정보에서 핵심 내용을 빠르게 파악하고, 문제에서 요구하는 답을 정확하게 제시하는 것이 중요합니다.
            </li>
            <li>
            <strong>Part 5:</strong> 주어진 주제에 대해 자신의 의견을 말하는 문제로, 준비시간 45초와 답변시간 60초가 주어집니다. 준비시간 동안 자신의 의견을 정리하고, 그 의견을 뒷받침할 두 가지 이유와 가능하면 자신의 경험을 예로 들어 설명해보세요.
            </li>
        </ul>
        </div>

      {/* Start Test Button */}
      <button
        className="px-6 py-3 rounded-lg bg-[#009963] text-xl text-white mt-8 w-full max-w-4xl mx-auto"
        onClick={handleStartTest}
      >
        모의고사 시작하기!
      </button>
    </div>
  );
};

export default MockTestDescription;
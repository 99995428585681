import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

const Part1Description = () => {
  const navigate = useNavigate();
  const [showTips, setShowTips] = useState(false);  // State to toggle tips visibility
  const audioRef = useRef(new Audio(`${process.env.REACT_APP_S3_BASE_URL}/recordings/part1-question.mp3`));  // Part 2 audio

  // Start the practice and stop the audio
  const startPractice = () => {
    if (audioRef.current) {
      audioRef.current.pause();  // Stop the audio
    }
    navigate('/practice-part1');  // Navigate to Part 2 practice page
  };

  // Play audio on component mount
  React.useEffect(() => {
    mixpanel.track('Part 1 Description Page Loaded');

    if (audioRef.current) {
      audioRef.current.play().catch(err => console.error('Audio failed to play:', err));
    }
    
    return () => {
      // Stop the audio when the component unmounts
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    };
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-4">
      <h1 className="text-2xl font-bold text-center mb-8">
        Part 1: Read a text aloud
      </h1>

      {/* h2 with same width as the tips box */}
      <h2 className="text-base lg:text-lg mb-8 w-full max-w-4xl mx-auto">
      In this section, you will read a short text aloud. You will have 45 seconds to read the text and prepare for your response.
      </h2>

      {/* Button to show/hide tips */}
      <button
        className="px-6 py-3 rounded-lg bg-[#F5F0E5] text-black text-lg w-full max-w-4xl mx-auto mb-4"
        onClick={() => setShowTips(!showTips)}
      >
        Part1 <strong>만점 Tip</strong> 확인하기
      </button>

      {/* Box for tips and template with stroke color */}
      {showTips && (
        <div className="border-2 border-[#A1824A] p-6 rounded-lg w-full max-w-4xl mx-auto">
          <p className="text-base lg:text-lg mb-4">
            <strong>Tips:</strong> 
          </p>

          {/* Template Section */}
          <div className="mb-6">
            <p className="text-base lg:text-lg mb-4">
              <strong>1.</strong> 파트1은 준비 시간 45초 동안 크게 읽으면서 잘 안 읽히는 단어들을 반복하며 연습해보세요.
            </p>
            <p className="text-base lg:text-lg mb-4">
              <strong>2.</strong> 뒤에 s가 붙는 단어, the가 ‘더'인지 (뒤에 모음이 나올 경우) ‘디'인지 확인해주세요.
            </p>

            <p className="text-base lg:text-lg mb-4">
              <strong>3.</strong> 발음을 정확하게 하는 것도 중요하지만, 잘 끊어 읽고, 억양과 강세를 잘 살려주는 것도 만점을 받을 수 있는 팁입니다!
            </p>
            <p className="text-base lg:text-lg mb-4">
              <strong>4.</strong> 빠르게 읽으려고 하지 말고 자연스럽게 읽고난 후, 배토스 AI튜터가 지적한 단어를 집중적으로 연습해보세요!
            </p>
            
          </div>
        </div>
      )}

      {/* Button with updated color */}
      <button
        className="px-6 py-3 rounded-lg bg-[#009963] text-xl text-white mt-8"
        onClick={startPractice}
      >
        파트 1 연습 시작하기!
      </button>
    </div>
  );
};

export default Part1Description;
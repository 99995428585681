import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import {IconPaths, PracticeStatics, ResultStatics, Part1Statics, LoadingStatics, TailwindStatics, ColorStatics} from '../statics';
import TopNavigator from '../components/TopNavigator';
import { ContentContainerBox, TertiaryBox } from '../components/Container';
import PrimaryButton from '../components/PrimaryButtion';
import Loading from '../components/Loading';
import IconText from '../components/IconText';
import CustomAlert from '../components/CustomAlert';
import { DoughnutChart, AnswerContainer } from '../components/Part1Components';
import { useAuth } from '../context/AuthProvider';
import { useNavigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

const PracticePart1 = () => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const { user } = useAuth();
    const navigate = useNavigate();

    const practiceDuration = 45;
    const speakingDuration = 45;

    // timer 설정
    const [timer, setTimer] = useState(practiceDuration);
    const timerRef = useRef(null);

    // user recording 설정
    const mediaRecorderRef = useRef(null);
    const recordedChunksRef = useRef([]);
    const [recording, setRecording] = useState(false);
    const [userRecordingAudioBlob, setUserRecordingAudioBlob] = useState(null);
    const [userRecordingDuration, setUserRecordingDuration] = useState(null);

    // page 렌더링 flags
    const [isLoadingPage, setIsLoadingPage] = useState(false);
    const [isResultPage, setIsResultPage] = useState(false);
    const [azureAiScore, setAzureAiScore] = useState({});

    // ProgressBar 설정
    const [question, setQuestion] = useState('');
    const [questionId, setQuestionId] = useState('');
    const [progress, setProgress] = useState({ completed: 0, total: 10 });
    const [completedQuestions, setCompletedQuestions] = useState([]);
    const progressPercentage = (completedQuestions.length / progress.total) * 100;

    // CustomAlert Component 설정
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertButtonText, setAlertButtonText] = useState('');
    const [navigateHome, setNavigateHome] = useState(false);
  
    useEffect(() => {
      if (user) {
        console.log("Fetching progress and question for user:", user);
        mixpanel.track('Part 1 Practice Started', { user_id: user.user_id });
        axios.get(`${API_BASE_URL}/api/get-part1-progress/${user.user_id}`)
          .then((response) => {
            const { completed, total, completedQuestions } = response.data;
            setProgress({ completed, total });
            setCompletedQuestions(completedQuestions);
  
            const nextQuestionId = completed === 0 ? 'p1_i_001' : `p1_i_${String(completed + 1).padStart(3, '0')}`;
            fetchNextQuestion(nextQuestionId);
          })
          .catch(error => {
            console.error("Error fetching the progress and question:", error);
          });
      }
    }, [user, API_BASE_URL]);

    useEffect(() => {
      if (timer === 0 ) {
        if (recording) {
          if (!userRecordingDuration) {
            setUserRecordingDuration(speakingDuration - timer);
          } 
          if (userRecordingDuration <= 1) {
            handleShowAlert(
              "녹음이 너무 짧으면 결과를 제대로 확인할 수 없습니다. 다시 시도해주세요. ",
              "다시 시작하기"
            );
          } else {
            handleStopRecording();
          }
        } else {
          handleStartRecording();
        }
      }
    }, [timer]);

    const fetchNextQuestion = (nextQuestionId) => {
      axios.get(`${API_BASE_URL}/api/get-part1-question-by-id/${nextQuestionId}`)
        .then((response) => {
          setQuestion(response.data.question);
          setQuestionId(nextQuestionId);
          startCountdown(practiceDuration); 
        })
        .catch(error => {
          console.error("Error fetching the next question:", error);
          handleShowAlert(
            "문제를 가져오지 못했습니다. 다시 시도해주세요.",
            "다시 시작하기",
            true
          );
        });
    };
  
    const handleRefresh = () => {
      window.location.reload();
    };

    const fetchQuestionById = (id) => {
      if (id <= progress.total) {
        const questionId = `p1_i_${String(id).padStart(3, '0')}`;
        fetchNextQuestion(questionId);
      } else {
        navigate('/'); 
      }
    };

    const handleDropdownChange = (event) => {
      const selectedQuestionId = parseInt(event.target.value);
      if (selectedQuestionId <= progress.completed+1) {
        fetchQuestionById(selectedQuestionId);
      }
    };

    const calculateAverageScore = (scoreList) => {
      if (scoreList.length === 0) return 0;
      const total = scoreList.reduce((acc, score) => acc + score, 0); 
      return Math.round((total / scoreList.length) * 100) / 100;
    };

    const startCountdown = (duration) => {
        setTimer(duration);
        clearInterval(timerRef.current);
        timerRef.current = setInterval(() => {
          setTimer(prev => {
            if (prev <= 1) {
              clearInterval(timerRef.current);
              return 0;
            }
            return prev - 1;
          });
        }, 1000);
      };

  const onClickStopRecording = () => {
    if (!userRecordingDuration) {
      setUserRecordingDuration(speakingDuration - timer);
    }
    setTimer(0);
  };
    
  const handleStartRecording = async () => {
    console.log("recording ...");
    setRecording(true);
    mixpanel.track('Part 1 Recording Started', { user_id: user.user_id, question_id: questionId });

    startCountdown(speakingDuration);

    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorderRef.current = new MediaRecorder(stream);
    recordedChunksRef.current = [];

    mediaRecorderRef.current.ondataavailable = (event) => {
      if (event.data.size > 0) {
        recordedChunksRef.current.push(event.data);
      }
    };

    mediaRecorderRef.current.start();
  };

  const handleStopRecording = () => {
    console.log("recording stopped ...");
    setRecording(false);
    clearInterval(timerRef.current);

    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop(); 

      if (mediaRecorderRef.current.stream) {
        mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
      }
    }

    mediaRecorderRef.current.onstop = async () => {
      if (!recordedChunksRef.current.length) return;
      setIsLoadingPage(true);
      const blob = new Blob(recordedChunksRef.current, { type: 'audio/wav' });
      setUserRecordingAudioBlob(blob);

      const formData = new FormData();
      formData.append('file', blob, 'recording.wav');
      formData.append('reference_text', question);
      try {
        const response = await axios.post(`${API_BASE_URL}/api/get-azure-pronunciation-assessment`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        const result = response.data;
        result["average_score"] = calculateAverageScore([
          result.fluency_score,
          result.accuracy_score,
          result.prosody_score,
          result.completeness_score,
        ]);
        console.log(result);
        setAzureAiScore(result);
        } catch (error) {
          console.error("There was an error transcribing the audio!", error);
          
          handleShowAlert(
            "녹음이 적절하지 않아 결과를 불러올 수 없습니다! 다시 시도해주세요.",
            "다시 시작하기"
          );
        } finally {
          recordedChunksRef.current = [];
        }      
      };
  }

  const handleShowResult = () => {
    setIsLoadingPage(false);
    setIsResultPage(true);
  }

  const handleNextQuestion = () => {
    if (user && questionId) {
      axios.post(`${API_BASE_URL}/api/complete-part1-question/${user.user_id}/${questionId}`)
        .then(() => {
          mixpanel.track('Part 1 Question Completed', { user_id: user.user_id, question_id: questionId });

          setProgress(prev => ({
            ...prev,
            completed: prev.completed + 1,
          }));
          handleRefresh();
        })
        .catch(error => {
          console.error("Error marking the question as completed:", error);
        });
    }
  };

const handleAlertButton = (navigateHome) => {
  setShowAlert(false);
  if (navigateHome) {
    navigate('/');
  } else {
    handleRefresh();
  }
};

const handleShowAlert = (alertMessage, buttonText, navigateHome = false) => {
  setAlertMessage(alertMessage);
  setAlertButtonText(buttonText);
  setNavigateHome(navigateHome);
  setShowAlert(true); 
};

function extractLastNumbers(id) {
  const match = id.match(/\d+$/);
  return match ? match[0] : '';
}

  return (
    <div>
      <div className="relative min-h-screen pt-4">
          <TopNavigator title={Part1Statics.TOPNAVIGATOR_STRING} />
          <div className="flex flex-col min-h-screen p-4 sm:p-8">
          {showAlert && (
            <div className="min-h-screen flex items-center justify-center">
              <CustomAlert 
              message={alertMessage}
              onCloseButtonText={alertButtonText}
              onClose={()=>handleAlertButton(navigateHome)}
            />
          </div>
          )}
            {!isLoadingPage && !isResultPage && (
              <>
              {/* Dropdown for Questions */}
                <div className={`relative w-full max-w-lg mx-auto ${TailwindStatics.MINIMUM_WIDTH_200}`}>
                  <div className="mb-4">
                    <select
                      className="border rounded p-2 w-full"
                      value={parseInt(questionId.split('_')[2])}  // Correctly extract the current question number
                      onChange={handleDropdownChange}
                    >
                      {Array.from({ length: progress.completed + 1 }, (_, i) => i + 1).map(number => (
                        <option key={number} value={number}>
                          Question {number}
                        </option>
                      ))}
                    </select>
                  </div>
                    <div className="mb-4">
                      <p className="text-base sm:text-lg">진행상황</p>
                      <div className="relative w-full h-4 bg-gray-300 rounded">
                        <div
                          className="absolute h-full bg-[#009963] rounded"
                          style={{ width: `${progressPercentage}%` }}
                        ></div>

                        {/* Display the completed number under the progress point */}
                        <div
                          className="absolute top-6 text-sm sm:text-base"
                          style={{ left: `calc(${progressPercentage}% - 10px)` }} // Adjust the position
                        >
                          {completedQuestions.length}
                      </div>
                    </div>
                    <div className="flex justify-between mt-2 text-sm sm:text-base">
                      <span></span> {/* Empty span for alignment */}
                      <span>{progress.total}</span>
                    </div>
                  </div>
                </div>
                <ContentContainerBox>
                  <div className="flex flex-col items-start space-y-2">
                  <img src={IconPaths.CONTAINER_BOOK_OPEN} alt="Icon" className="w-6 h-6" />
                    <span className="text-md font-sans font-light">
                      {question}
                    </span>
                  </div>
              </ContentContainerBox>
              <div style={{ flex: '0.7 1 auto', maxHeight: 'calc(100vh - 4rem)', overflow: 'auto' }} />
                <div className="flex flex-col items-center space-y-4">
                {!recording ? (
                  <>
                    <TertiaryBox
                      iconPath={IconPaths.CONTAINER_BOOK_OPEN}
                      text= {PracticeStatics.PRACTICE_TERTIARY}
                      textColor = {ColorStatics.BASIC_BLACK}
                      backgroundColor={ColorStatics.BASIC_WHITE}
                    />
                    <div>
                      Time left : {timer.toString().padStart(2, '0')}
                    </div>
                    <PrimaryButton 
                      text= {PracticeStatics.START_RECORDING}
                      textColor= {ColorStatics.BASIC_WHITE}
                      backgroundColor= {ColorStatics.MAIN_GREEN}
                      onClick={()=> handleStartRecording()}
                    />
                  </>
                ) : (
                    <>
                      <TertiaryBox
                        iconPath={IconPaths.CONTAINER_MICROPHONE_BLACK}
                        text= {PracticeStatics.RECORDING_TERTIARY}
                        textColor =  {ColorStatics.BASIC_BLACK}
                        backgroundColor= {ColorStatics.BASIC_WHITE}
                      />
                      <div>
                        Time left : {timer.toString().padStart(2, '0')}
                      </div>
                      <PrimaryButton 
                        text={PracticeStatics.STOP_RECORDING}
                        textColor= {ColorStatics.BASIC_WHITE}
                        backgroundColor={ColorStatics.RED_700}
                        onClick={()=> onClickStopRecording()}
                      />
                  </>
                )}
                </div>
              </>
            )}
            {isLoadingPage && !isResultPage && (
              <>
                <Loading
                  iconPath={IconPaths.LOADING_VERIFIED}
                  text={LoadingStatics.LOADING_TEXT}
                />
                {azureAiScore.words && (
                  <>
                    <div style={{ flex: '0.9 1 auto', maxHeight: 'calc(100vh - 4rem)', overflow: 'auto' }} />
                      <div className="flex justify-center">
                        <PrimaryButton 
                          iconPath={IconPaths.PRIMARY_ARROW_RIGHT} 
                          text={LoadingStatics.LOADING_PRIMARY}
                          textColor = {ColorStatics.BASIC_WHITE}
                          backgroundColor={ColorStatics.MAIN_GREEN}
                          iconPosition="right" 
                          onClick={()=>handleShowResult()}
                        />
                  </div>
                </>
                )}
              </>
            )}
            {isResultPage && !isLoadingPage && (
              <>
                <div className="flex flex-col items-start space-y-5">
                  <IconText
                    iconPath={IconPaths.ICON_TEXT_CLIPBOARD}
                    text={ResultStatics.RESULT_OVERALL}
                  />
                </div>
                <div className='mt-5 mb-5'>
                  <ContentContainerBox>
                  <div className="flex justify-center text-md font-sans font-semibold">
                    Overall Score
                  </div>
                  <DoughnutChart score={azureAiScore.average_score}/>
                    <div className="text-md font-sans">
                        <p className="font-['Inter']">Fluency Score : {azureAiScore.fluency_score}</p>
                        <p className="font-['Inter']">Accuracy Score : {azureAiScore.accuracy_score}</p>
                        <p className="font-['Inter']">Prosody Score : {azureAiScore.prosody_score}</p>
                        <p className="font-['Inter']">Completeness Score : {azureAiScore.completeness_score}</p>
                        <p className="font-['Inter']"
                        style={{ fontWeight: 'bold' }}
                        >Average : {azureAiScore.average_score}</p>
                    </div>
                  </ContentContainerBox>
                </div>
                <IconText
                  iconPath={IconPaths.ICON_TEXT_VIDEO_CAMERA}
                  text={ResultStatics.RESULT_RECAP}
                />
                <div className='mt-5 mb-5'>
                  <AnswerContainer
                   userRecordingAudioBlob={userRecordingAudioBlob}
                   userRecordingDuration={userRecordingDuration}
                   azureAiScore={azureAiScore}
                   questionId={parseInt(extractLastNumbers(questionId), 10)}
                   />
                </div>
                  <div className="flex justify-center">
                    <PrimaryButton 
                        text="Try Again"
                        textColor= {ColorStatics.BASIC_WHITE}
                        backgroundColor={ColorStatics.MAIN_GREEN}
                        onClick={()=>handleRefresh()}
                      />
                </div>
                <div className="mt-5 flex justify-center">
                  <PrimaryButton
                      text="Next Question"
                      textColor = {ColorStatics.BASIC_BLACK}
                      backgroundColor={ColorStatics.MAIN_YELLOW}
                      onClick={()=>handleNextQuestion()}
                    />
                  </div>
              </>
                )}
          </div>
        </div>
    </div>
  );
};

export default PracticePart1;
import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

const Part3Description = () => {
  const navigate = useNavigate();
  const [showTips, setShowTips] = useState(false);  // State to toggle tips visibility
  const audioRef = useRef(new Audio(`${process.env.REACT_APP_S3_BASE_URL}/recordings/part3-practice-question.mp3`));  // Part 3 audio

  // Start the practice and stop the audio
  const startPractice = () => {
    if (audioRef.current) {
      audioRef.current.pause();  // Stop the audio
    }
    navigate('/practice-part3');  // Navigate to Part 3 practice page
  };

  // Play audio on component mount
  React.useEffect(() => {
    mixpanel.track('Part 3 Description Page Loaded');
    if (audioRef.current) {
      audioRef.current.play().catch(err => console.error('Audio failed to play:', err));
    }
    
    return () => {
      // Stop the audio when the component unmounts
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    };
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-4">
      <h1 className="text-2xl font-bold text-center mb-8">
        Part 3: Respond to questions
      </h1>

      <h2 className="text-base lg:text-lg mb-8 w-full max-w-4xl mx-auto">
        In Part 3, you will answer three questions. You will have 3 seconds to prepare after each question, and you will have 15 seconds to respond to the first two questions and 30 seconds for the third question.
      </h2>

      {/* Button to show/hide tips */}
      <button
        className="px-6 py-3 rounded-lg bg-[#F5F0E5] text-black text-lg w-full max-w-4xl mx-auto mb-4"
        onClick={() => setShowTips(!showTips)}
      >
        Tips & Part 3 템플릿 확인하기
      </button>

      {/* Box for tips and template with stroke color */}
      {showTips && (
        <div className="border-2 border-[#A1824A] p-6 rounded-lg w-full max-w-4xl mx-auto">
          <p className="text-base lg:text-lg mb-4">
            <strong>Tips:</strong>
          </p>
          <div className="mb-6">
            <p className="text-base lg:text-lg mb-4">
              <strong>1:</strong> 파트3는 상황 설명 문구가 제시된 후, 관련된 질문 3가지에 대답을 하는 문제입니다.
            </p>
            <p className="text-base lg:text-lg mb-4">
              <strong>2:</strong> 실제 시험에서는 안내문구가 보여진 후, 질문을 읽은 뒤 준비시간 3초가 주어지지만, 본 연습 세션에서는 준비시간 30초가 주어집니다.
            </p>
            <p className="text-base lg:text-lg mb-8">
              <strong>3: 제시된 질문의 표현과 문장구조를 활용해 문장을 구성하고 답을 명확히 하는 것이 중요합니다!</strong>
            </p>
            <p className="text-base lg:text-lg mb-4">
              예시 안내문: Imagine that a friend will be moving to your neighborhood. You are having a telephone conversation
              about where you live.
            </p>

            {/* Question 5 */}
            <p className="text-base lg:text-lg mb-4">
              Q: <strong>How many</strong> grocery stores are in your neighborhood, and <strong>can you walk to them</strong>?
            </p>
            <p className="text-base lg:text-lg mb-4">
              템플릿: <strong>There are [숫자] grocery stores</strong> in my neighborhood, and <strong>I [can/cannot] walk to them</strong>.
            </p>
            <p className="text-base lg:text-lg mb-4">
              모범답안: There are two grocery stores in my neighborhood, and I can walk to both of them.
            </p>

            {/* Question 6 */}
            <p className="text-base lg:text-lg mb-4">
              <strong>Q: What’s the best time of day </strong>to go to the grocery store, and <strong>why</strong>?
            </p>
            <p className="text-base lg:text-lg mb-4">
              템플릿: <strong>The best time of day to go to the grocery store is [time]</strong>, because <strong>[reason]</strong>.
            </p>
            <p className="text-base lg:text-lg mb-4">
              모범답안: The best time of day to go to the grocery store is early morning on weekdays, because it’s less crowded and the shelves are freshly stocked.
            </p>

            {/* Question 7
            <p className="text-base lg:text-lg mb-4">
              <strong>Q: Do you usually buy all your groceries from the same store</strong>? <strong>Why or why not</strong>?
            </p>
            <p className="text-base lg:text-lg mb-4">
              템플릿: <strong>I [do/do not] usually buy all my groceries from the same store </strong>because <strong>[reason]</strong>.
            </p>
            <p className="text-base lg:text-lg mb-4">
              모범답안: I don’t usually buy all my groceries from the same store because I prefer getting certain items from specific stores based on quality and price.
            </p> */}
          </div>
        </div>
      )}

      {/* Button to start practice */}
      <button
        className="px-6 py-3 rounded-lg bg-[#009963] text-xl text-white mt-8"
        onClick={startPractice}
      >
        파트 3 연습 시작하기!
      </button>
    </div>
  );
};

export default Part3Description;
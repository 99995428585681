import React from 'react';
import {TailwindStatics} from '../statics';

const ContentContainerBox = ({ 
  children
}) => {
    const shadowX = 0;
    const shadowY = 1;
    const shadowBlur = 4;
    const shadowColor = '#0C0C0D';
    const shadowOpacity = 0.1;
    const boxShadow = `${shadowX}px ${shadowY}px ${shadowBlur}px ${shadowColor}`;
  
    return (
        <div className={`relative p-6 bg-white rounded-lg w-full max-w-lg mx-auto ${TailwindStatics.MINIMUM_WIDTH_200}`}>
            <div 
                className="absolute inset-0 rounded-lg"
                style={{ boxShadow, opacity: shadowOpacity, pointerEvents: 'none'}}
            />
            <div>{children}</div>
        </div>
    );
};

const TertiaryBox = ({ 
    iconPath,
    text, 
    textColor,
    backgroundColor = '#FFFFFF',
  }) => {
    return (
      <div
        className={`flex items-center justify-center p-2 font-sans font-semibold ${TailwindStatics.MINIMUM_WIDTH_100}`}
        style={{
            backgroundColor: backgroundColor, 
            color: textColor,
            padding: '8px 12px',
            borderRadius: '8px',
            border: '1px solid #E2E8F0',
            fontSize: '0.875rem',
            display: 'inline-flex',
        }}
        >
        <span className="mr-1">
            <img src={iconPath} alt="Icon" className="w-5 h-5" />
        </span>
        <span>{text}</span>
      </div>
    );
  };

export {ContentContainerBox, TertiaryBox};

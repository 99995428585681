import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

const Part4Description = () => {
  const navigate = useNavigate();
  const [showTips, setShowTips] = useState(false);  // State to toggle tips visibility
  const audioRef = useRef(new Audio(`${process.env.REACT_APP_S3_BASE_URL}/recordings/part4-practice-question.mp3`));  // Part 2 audio

  // Start the practice and stop the audio
  const startPractice = () => {
    if (audioRef.current) {
      audioRef.current.pause();  // Stop the audio
    }
    navigate('/practice-part4');  // Navigate to Part 2 practice page
  };

  // Play audio on component mount
  React.useEffect(() => {
    mixpanel.track('Part 4 Description Page Loaded');
    if (audioRef.current) {
      audioRef.current.play().catch(err => console.error('Audio failed to play:', err));
    }
    
    return () => {
      // Stop the audio when the component unmounts
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    };
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-4">
      <h1 className="text-2xl font-bold text-center mb-8">
        Part 4: Respond to Questions Using Information Provided
      </h1>

      {/* h2 with same width as the tips box */}
      <h2 className="text-base lg:text-lg mb-8 w-full max-w-4xl mx-auto">
        In this part of the practice, you will answer three questions based on the information provided. You will have 45 seconds to read the inforamtion and prepare your response. You will have 15 seconds to respond to the first two questions and 30 seconds for the third question.
      </h2>

      {/* Button to show/hide tips */}
      <button
        className="px-6 py-3 rounded-lg bg-[#F5F0E5] text-black text-lg w-full max-w-4xl mx-auto mb-4"
        onClick={() => setShowTips(!showTips)}
      >
        Tips & Part4 템플릿 공부하기
      </button>

      {/* Box for tips and template with stroke color */}
      {showTips && (
        <div className="border-2 border-[#A1824A] p-6 rounded-lg w-full max-w-4xl mx-auto">
          <p className="text-base lg:text-lg mb-4">
            <strong>Tips:</strong> 
          </p>

          {/* Template Section */}
          <div className="mb-6">
            <p className="text-base lg:text-lg mb-4">
              <strong>1.</strong> 실제 시험에서 파트 4는 표가 제시된 후 45초간 표를 읽는 시간이 주어집니다. 그 다음 문제가 제시되고 3초간의 준비 시간 후 답변을 하게 됩니다. 그러나 이 연습 세션에서는 표를 읽고 각 문제를 준비하는 시간을 합쳐 45초를 제공합니다.
            </p>
            <p className="text-base lg:text-lg mb-4">
              <strong>2.</strong> 세 문제 중 첫 번째 문제는 주로 주어진 표의 장소와 시간 또는 특정 사실을 묻습니다. 다음과 같은 표현을 익혀주세요!
            </p>
            <p className="text-base lg:text-lg mb-4">
              <strong>A.</strong> The event will start at (시작하는 시간) 
            </p>
            <p className="text-base lg:text-lg mb-4">
              <strong>B.</strong> The event will finish at (끝나는 시간)
            </p>
            <p className="text-base lg:text-lg mb-4">
              <strong>C. </strong>The event will be held at/in (이벤트의 장소)
            </p>
            <p className="text-base lg:text-lg mb-4">
              <strong>3.</strong> 두 번째 질문은 질문자의 정보가 사실인지 아닌지를 묻기도 합니다. 다음의 표현을 익혀두세요.
            </p>
            <p className="text-base lg:text-lg mb-4">
              (예시 템플릿) I'm sorry, but you have the wrong information. The schedule was cancelled and changed to (변경된 일정, 만약 제시되었다면). 
            </p>
            <p className="text-base lg:text-lg mb-4">
              <strong>4.</strong> 또한, 두 번째와 세 번째 문제는 주어진 표의 여러 가지 사실을 나열해야 하는 경우가 있습니다. 이때 다음과 같은 표현을 활용하세요.
            </p>
            <p className="text-base lg:text-lg mb-4">
              (예시 템플릿) There are (수량) (질문된 부분). The first is, (첫 번째 사실) on (날짜) / at (시작하는 시간) to (끝나는 시간) that will be given by (강연자 혹은 주도자).
            </p>
            <p className="text-base lg:text-lg mb-4">
              (예시 문장) There are two events scheduled for children. The first is a magic show on Saturday at 2 p.m. to 2:15 p.m. that will be given by Rachel. The second is a lego class on Sunday at 3 p.m. that will be held by Professor John.
            </p>
       
          </div>
        </div>
      )}

      {/* Button with updated color */}
      <button
        className="px-6 py-3 rounded-lg bg-[#009963] text-xl text-white mt-8"
        onClick={startPractice}
      >
        파트 4 연습 시작하기!
      </button>
    </div>
  );
};

export default Part4Description;
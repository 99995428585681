import React, { useEffect } from 'react';
import { Routes, Route, useLocation, BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/AuthProvider';  // Import AuthProvider
import mixpanel from 'mixpanel-browser';  // Import Mixpanel
import LandingPage from './pages/LandingPage';
import PracticePart2 from './pages/PracticePart2';
import PracticePart1 from './pages/PracticePart1';
import PricingPage from './pages/PricingPage';
import PracticePart3 from './pages/PracticePart3';
import PracticePart4 from './pages/PracticePart4';
import PracticePart5 from './pages/PracticePart5';
import LoginPage from './pages/LoginPage'; 
import Part1Description from './components/Part1Description';
import Part2Description from './components/Part2Description';
import Part4Description from './components/Part4Description';
import Part5Description from './components/Part5Description';
import Part3Description from './components/Part3Description';
import MockTest1 from './pages/MockTest1';
import SignUpPage from './pages/SignUpPage';
import PrivateRoute from './components/PrivateRoute';  // Import PrivateRoute
import MockTestDescription from './components/MockTestDescription';

// Component to track page views and identify users
// const MixpanelTracker = () => {
//   const location = useLocation();  // Get current location
//   const { user } = useAuth();      // Get the authenticated user

//   useEffect(() => {
//     // Initialize Mixpanel
//     mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
//       debug: true,           // Enable debug mode for development
//       track_pageview: "url-with-path",   // Automatically track page views
//     });

//     if (user) {
//       // Identify user if authenticated
//       mixpanel.identify(user.user_id);  // Use user ID to identify
//       mixpanel.people.set({
//         "$name": user.name,
//         "$email": user.email,           // Optional: Set user info
//       });
//     }

//     // Track the current page view
//     mixpanel.track("Page View", {
//       "page": location.pathname         // Track the page being viewed
//     });

//   }, [location, user]); // Re-run when the location or user changes

//   return null;  // This component does not render anything
// };

function App() {

  useEffect(() => {
    // Initialize Mixpanel
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
      debug: true,
    });
  }, []);

  return (
    <div className="App">
      {/* <Router> */}
      
        <AuthProvider>
          {/* <MixpanelTracker /> */}
          <Routes>
            {/* Public routes */}
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/" element={<LandingPage />} />

            {/* Protected routes */}
            <Route element={<PrivateRoute />}>
              <Route path="/part1-description" element={<Part1Description />} />
              <Route path="/practice-part1" element={<PracticePart1 />} />
              <Route path="/part2-description" element={<Part2Description />} />
              <Route path="/practice-part2" element={<PracticePart2 />} />
              <Route path="/practice-part3" element={<PracticePart3 />} />
              <Route path="/part3-description" element={<Part3Description />} />
              <Route path="/practice-part4" element={<PracticePart4 />} />
              <Route path="/part4-description" element={<Part4Description />} />
              <Route path="/practice-part5" element={<PracticePart5 />} />
              <Route path="/part5-description" element={<Part5Description />} />
              <Route path="/pricing" element={<PricingPage />} />
              <Route path="/mocktest-description" element={<MockTestDescription />} />
              <Route path="/mocktest1" element={<MockTest1 />} />
            </Route>
          </Routes>
        </AuthProvider>
      {/* </Router> */}
    </div>
  );
}

export default App;
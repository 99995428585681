import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

const Part2Description = () => {
  const navigate = useNavigate();
  const [showTips, setShowTips] = useState(false);  // State to toggle tips visibility
  const audioRef = useRef(new Audio(`${process.env.REACT_APP_S3_BASE_URL}/recordings/part2-practice-prompt.mp3`));  // Part 2 audio

  // Start the practice and stop the audio
  const startPractice = () => {
    if (audioRef.current) {
      audioRef.current.pause();  // Stop the audio
    }
    navigate('/practice-part2');  // Navigate to Part 2 practice page
  };

  // Play audio on component mount
  React.useEffect(() => {
    mixpanel.track('Part 2 Description Page Loaded');

    if (audioRef.current) {
      audioRef.current.play().catch(err => console.error('Audio failed to play:', err));
    }
    
    return () => {
      // Stop the audio when the component unmounts
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    };
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-4">
      <h1 className="text-2xl font-bold text-center mb-8">
        Part 2: Describe a picture
      </h1>

      {/* h2 with same width as the tips box */}
      <h2 className="text-base lg:text-lg mb-8 w-full max-w-4xl mx-auto">
        In this part of the practice, you will describe the picture on your screen in as much detail as you can. You will have 45 seconds to prepare your response. Then you will have 30 seconds to speak about the picture.
      </h2>

      {/* Button to show/hide tips */}
      <button
        className="px-6 py-3 rounded-lg bg-[#F5F0E5] text-black text-lg w-full max-w-4xl mx-auto mb-4"
        onClick={() => setShowTips(!showTips)}
      >
        Tips & Part2 템플릿 확인하기
      </button>

      {/* Box for tips and template with stroke color */}
      {showTips && (
        <div className="border-2 border-[#A1824A] p-6 rounded-lg w-full max-w-4xl mx-auto">
          <p className="text-base lg:text-lg mb-4">
            <strong>Tips:</strong> 
          </p>

          {/* Template Section */}
          <div className="mb-6">
            <p className="text-base lg:text-lg mb-4">
              <strong>A:</strong> 파트2는 사진 묘사하기 문제입니다. 사진에서 강조된, 보여지는 부분을 정확한 어휘, 발음, 문법을 사용한다면 만점을 받을 수 있어요. 
            </p>
            <p className="text-base lg:text-lg mb-4">
              <strong>B:</strong> 준비시간 45초 동안, 최대한 많은 명사 (대상, 장소)와 그에 대한 동사 (행동), 형용사 (크기, 길이, 감정, 재질, 수량) 를 노트에 적어보세요, 실전처럼!
            </p>
            <p className="text-base lg:text-lg mb-4">
              <strong>파트2 템플릿:</strong> 
            </p>
            <p className="text-base lg:text-lg mb-4">
              <strong>A. 먼저, 장소와 날씨(낮/밤)를 설명하세요</strong>  
            </p>
            <p className="text-base lg:text-lg mb-4">
              The picture was taken in/at (장소) during (날씨/시간대). 
            </p>
            <p className="text-base lg:text-lg mb-4">
              예: The picture was taken in a park during a rainy day/night.  
            </p>
            <p className="text-base lg:text-lg mb-4">
              <strong>B. 사람 또는 사물을 묘사하세요:</strong>  
            </p>
            <p className="text-base lg:text-lg mb-4">
              There is/are (명사) + (동작/외모 설명).
            </p>
            <p className="text-base lg:text-lg mb-4">
              예: There are two people sitting on a bench.
            </p>
            <p className="text-base lg:text-lg mb-4">
              여러 명인 경우, 추가로 각각의 동작을 묘사하세요:
            </p>
            <p className="text-base lg:text-lg mb-4">
              예: One is looking at her phone <strong>while </strong>the other is looking at the presentation.
            </p>
            <p className="text-base lg:text-lg mb-4">
              <strong>3: 배경을 설명하세요.</strong>  
            </p>
            <p className="text-base lg:text-lg mb-4">
              In the background, I can see (배경 설명).
            </p>
            <p className="text-base lg:text-lg mb-4">
              예: In the background, I can see trees and a building.
            </p>
            <p className="text-base lg:text-lg mb-4">
              <strong>4: 마지막으로 분위기를 언급하세요:</strong>  
            </p>
            <p className="text-base lg:text-lg mb-4">
              Overall, it seems like (분위기).
            </p>
            <p className="text-base lg:text-lg mb-4">
              예: Overall, it seems peaceful and calm.
            </p>
          </div>
        </div>
      )}

      {/* Button with updated color */}
      <button
        className="px-6 py-3 rounded-lg bg-[#009963] text-xl text-white mt-8"
        onClick={startPractice}
      >
        파트 2 연습 시작하기!
      </button>
    </div>
  );
};

export default Part2Description;
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import RecordButton from '../components/RecordButton';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthProvider';

const MockTest1 = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [currentPart, setCurrentPart] = useState(1);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questionData, setQuestionData] = useState(null);
  const [timer, setTimer] = useState(0);  // Preparation and answer timer
  const [recording, setRecording] = useState(false);
  const [recordingCompleted, setRecordingCompleted] = useState(false);
  const [partDescriptionPlaying, setPartDescriptionPlaying] = useState(true);  // Track if the part description is playing
  const [partDescriptionShown, setPartDescriptionShown] = useState(true);  // Show description before starting part
  const [audioEnded, setAudioEnded] = useState(false);  // Track if audio has finished playing
  const [isAnswerTime, setIsAnswerTime] = useState(false);  // Track if it's answer time
  const mediaRecorderRef = useRef(null);
  const recordedChunksRef = useRef([]);
  const timerRef = useRef(null);
  const audioRef = useRef(new Audio());  // Correct initialization for audio playback

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  // Fetch the mock test data for part 1
  useEffect(() => {
    fetchMockTest1Data();
  }, []);

  const fetchMockTest1Data = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/mocktest1`);
      console.log('Mock test data:', response.data);
      setQuestionData(response.data);
    } catch (error) {
      console.error('Error fetching mock test data:', error);
    }
  };

  const getTimersForCurrentPart = () => {
    // Ensure that questionData, parts, and questions are defined before accessing them
    if (!questionData || !questionData[`part${currentPart}`]) {
      return { preparationTime: 0, answerTime: 0 };
    }

    // Define timers for each part
    switch (currentPart) {
      case 1:
        return { preparationTime: 45, answerTime: 45 };
      case 2:
        return { preparationTime: 45, answerTime: 30 };
      case 3:
        if (currentQuestionIndex === 2) {
          return { preparationTime: 3, answerTime: 30 }; // For Question 7
        }
        return { preparationTime: 3, answerTime: 15 }; // For Questions 5 and 6
      case 4:
        if (currentQuestionIndex === 2) {
          return { preparationTime: 3, answerTime: 30 }; // For Question 10
        }
        return { preparationTime: 3, answerTime: 15 }; // For Questions 8 and 9
      case 5:
        return { preparationTime: 45, answerTime: 60 };
      default:
        return { preparationTime: 0, answerTime: 0 };
    }
  };

  const startCountdown = (duration, type) => {
    clearInterval(timerRef.current); // Clear any existing countdowns
    console.log(`Starting ${type} countdown for ${duration} seconds`);
    setTimer(duration); // Set the timer to the correct duration
  
    timerRef.current = setInterval(() => {
      setTimer((prev) => {
        if (prev <= 1) {
          clearInterval(timerRef.current); // Clear the countdown when it reaches 0
          if (type === 'preparation' && !isAnswerTime) {
            startRecordingCountdown(); // Switch to answer time when preparation ends
          } else if (recording) {
            stopRecording(); // Automatically stop recording when time runs out
          }
          return 0; // Return 0 as the final value when countdown ends
        }
        return prev - 1; // Decrease the timer by 1 each second
      });
    }, 1000); // Run the countdown every second
  };

  const startRecordingCountdown = () => {
    const { answerTime } = getTimersForCurrentPart();
    setIsAnswerTime(true);  // Mark it as answer time
    startCountdown(answerTime, 'recording');
  };

  const startPreparationCountdown = () => {
    const { preparationTime } = getTimersForCurrentPart();
    setIsAnswerTime(false);  // Reset to preparation mode
    startCountdown(preparationTime, 'preparation');
  };

  const startRecording = () => {
    clearInterval(timerRef.current);  // Stop the preparation timer
    setRecording(true);
    setRecordingCompleted(false);
    startRecordingCountdown();  // Start the answer timer
    handleStartRecording();
  };

  const stopRecording = () => {
    setRecording(false);
    setRecordingCompleted(true);
    handleStopRecording();
  };

  const handleStartRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorderRef.current = new MediaRecorder(stream);
    recordedChunksRef.current = [];

    mediaRecorderRef.current.ondataavailable = (event) => {
      if (event.data.size > 0) {
        recordedChunksRef.current.push(event.data);
      }
    };

    mediaRecorderRef.current.start();
  };

  const handleStopRecording = () => {
    if (!mediaRecorderRef.current || mediaRecorderRef.current.state === 'inactive') return;

    mediaRecorderRef.current.stop();

    const stream = mediaRecorderRef.current.stream;
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop()); // Stop recording icon
    }

    mediaRecorderRef.current.onstop = async () => {
      const blob = new Blob(recordedChunksRef.current, { type: 'audio/webm' });
      const file = new File([blob], 'recording.webm', { type: 'audio/webm' });

      const formData = new FormData();
      formData.append('file', file);
      formData.append('user_id', user.user_id); // Add user ID
      formData.append('question_id', questionData[`part${currentPart}`].questions[currentQuestionIndex].question_id); // Attach question ID

      

      try {
        await axios.post(`${API_BASE_URL}/api/submit-answer`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        // Move to next question
        handleNextQuestion();
      } catch (error) {
        console.error('Error submitting answer:', error);
      }
    };

    mediaRecorderRef.current = null;
  };

  const handleNextQuestion = () => {
    if (!questionData || !questionData[`part${currentPart}`]) {
      console.error("Question data or part is undefined");
      return;
    }

    const totalQuestionsInCurrentPart = questionData[`part${currentPart}`].questions.length;

    if (currentQuestionIndex < totalQuestionsInCurrentPart - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      startPreparationCountdown(); // Reset preparation time for the next question
    } else if (currentPart < 5) {
      setCurrentPart(currentPart + 1);
      setCurrentQuestionIndex(0);
      setPartDescriptionPlaying(true); // Show part description for the next part
      setPartDescriptionShown(true); // Show description for the next part
    } else {
      deductCredits();
      navigate('/completed'); // Navigate to completion page
    }
  };

  // useEffect(() => {
  //   if (!partDescriptionPlaying && !recording && timer === null && questionData) {
  //     startPreparationCountdown();  // This line automatically triggers the countdown, remove it
  //   }
  // }, [partDescriptionPlaying, currentQuestionIndex, questionData]);

  const handlePartDescriptionEnd = () => {
    stopAudio();  // Stop and reset audio
    setPartDescriptionPlaying(false);  // Mark the description as finished
    setAudioEnded(true);  // Enable the button for the user to proceed
    
    // Start the preparation timer only after the description ends
    startPreparationCountdown(); 
  };

  // Ensure part description audio is played when part starts
  useEffect(() => {
    if (partDescriptionPlaying) {
      playPartDescription(currentPart);  // Play the description when the part starts
    }
  }, [partDescriptionPlaying, currentPart]);

  // Play the part description audio
  const playPartDescription = (part) => {
    if (audioRef.current) {
      const audioSrc = `/recordings/part${part}_description.mp3`;  // Ensure correct audio source
      audioRef.current.src = audioSrc;

      audioRef.current.play()
        .then(() => {
          console.log(`Playing part ${part} description audio`);
        })
        .catch((err) => {
          console.error("Audio failed to play:", err);
        });

      // Trigger when the audio ends
      audioRef.current.onended = handlePartDescriptionEnd; 
    } else {
      console.error("AudioRef is not initialized");
    }
  };

  // Ensure preparation timer starts only after the description ends
  useEffect(() => {
    if (questionData && currentQuestionIndex >= 0 && !partDescriptionPlaying) {
      startPreparationCountdown(); // Automatically start preparation timer for new questions after description ends
    }
  }, [currentQuestionIndex, questionData, partDescriptionPlaying]);  // Ensure it runs when questionData or currentQuestionIndex changes, and the description has ended
    
const deductCredits = () => {
    axios.post(`${API_BASE_URL}/api/deduct-credits/${user.user_id}`, { credits: 10 })
      .then(() => console.log("Credits deducted"))
      .catch(err => console.error("Error deducting credits", err));
  };

  const handleStartPart = () => {
    if (audioRef.current) {
      audioRef.current.pause();  // Stop any remaining audio
      audioRef.current.currentTime = 0;  // Reset audio
    }
    setPartDescriptionShown(false);  // Hide part description
    setAudioEnded(false);  // Reset audio ended state for the next part
    startPreparationCountdown();  // Start the preparation timer ONLY when user clicks
  };

  const getInstructionsForPart = (part) => {
    switch (part) {
      case 1:
        return "Directions: In this part of the test, you will read aloud the text on the screen. You will have 45 seconds to prepare. Then you will have 45 seconds to read the text aloud.";
      case 2:
        return "Directions: In this part of the test, you will describe the picture on your screen in as much detail as you can. You will have 45 seconds to prepare your response. Then you will have 30 seconds to speak about the picture.";
      case 3:
        return "Directions: In this part of the test, you will answer three questions. You will have three seconds to prepare after you hear each question. You will have 15 seconds to respond to Questions 5 and 6, and 30 seconds to respond to Question 7.";
      case 4:
        return "Directions: In this part of the test, you will answer three questions based on the information provided. You will have 45 seconds to read the information before the questions begin. You will have three seconds to prepare and 15 seconds to respond to Questions 8 and 9. You will hear Question 10 two times. You will have three seconds to prepare and 30 seconds to respond to Question 10.";
      case 5:
        return "Directions: In this part of the test, you will give your opinion about a specific topic. Be sure to say as much as you can in the time allowed. You will have 45 seconds to prepare. Then you will have 60 seconds to speak.";
      default:
        return "";
    }
  };

  const stopAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-start p-4">
      {questionData ? (
        <>
          {partDescriptionPlaying ? (
          <div>
            <h1 className="text-2xl font-bold mb-4">모의고사 {currentPart} - Part Description</h1>
            {/* Display the instructions based on the current part */}
            <p className="mb-4">{getInstructionsForPart(currentPart)}</p>
            <audio ref={audioRef} className="hidden"></audio> {/* Audio element for playing description */}
            <button
            onClick={handleStartPart}
            disabled={!audioEnded}  // Ensure button is only enabled after audio ends
            className={`mt-4 px-4 py-2 rounded-lg text-white ${audioEnded ? 'bg-blue-600' : 'bg-gray-400 cursor-not-allowed'}`}
          >
            Start Part {currentPart}
          </button>
          </div>
        ): (
            <div>
              <h1 className="text-2xl font-bold mb-4">모의고사 {currentPart} - Question {currentQuestionIndex + 1}</h1>
              {/* Ensure questionData is defined before accessing parts and questions */}
              <p className="mb-4">{questionData[`part${currentPart}`]?.questions[currentQuestionIndex]?.text}</p>
              <p className="text-lg mb-4">
                {recording ? 'Answer Time' : 'Preparation Time'}: {timer} seconds
              </p>
              <RecordButton recording={recording} onStart={startRecording} onStop={stopRecording} />
            </div>
          )}
        </>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default MockTest1;
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { useAuth } from '../context/AuthProvider';  // Use AuthProvider context
import axios from 'axios';
import mixpanel from 'mixpanel-browser';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

const SignUpPage = () => {
  const [fullName, setFullName] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [daysUntilExam, setDaysUntilExam] = useState('');
  const [examPurpose, setExamPurpose] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { loginAction } = useAuth();  // Use loginAction to store token and user info
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const handleSignUp = async () => {
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const response = await axios.post(`${API_BASE_URL}/api/signup`, {
        full_name: fullName,
        user_id: username,
        password,
        days_until_exam: daysUntilExam,
        exam_purpose: examPurpose,
      });

      if (response.data.status === 'success') {

        mixpanel.track('Sign Up', { user_id: username });
        // Call loginAction to store the token and user information
        await loginAction({ user_id: username, password });
        navigate('/');  // Navigate to the landing page after sign-up
      } else {
        setError('Sign-up failed. Please try again.');
      }
    } catch (err) {
      console.error('Error during sign-up:', err);
      setError('아래 필수 항목을 모두 입력해주세요.');
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-4">
      {/* Back Button */}
      <button className="absolute top-4 left-4 text-xl" onClick={() => navigate(-1)}>
        ←
      </button>

      <h1 className="text-3xl font-bold mb-4">Create your account</h1>

      {/* Error message */}
      {error && <p className="text-red-500">{error}</p>}

      {/* Full Name Input */}
      <input
        className="mb-2 p-4 w-80 bg-[#F5F0E5] border-none rounded-lg text-[#A1824A] placeholder-[#A1824A]"
        type="text"
        placeholder="이름"
        value={fullName}
        onChange={(e) => setFullName(e.target.value)}
      />

      {/* Username Input */}
      <input
        className="mb-2 p-4 w-80 bg-[#F5F0E5] border-none rounded-lg text-[#A1824A] placeholder-[#A1824A]"
        type="text"
        placeholder="이메일"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />

      {/* Password Input */}
      <input
        className="mb-2 p-4 w-80 bg-[#F5F0E5] border-none rounded-lg text-[#A1824A] placeholder-[#A1824A]"
        type="password"
        placeholder="비밀번호"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />

      {/* Confirm Password Input */}
      <input
        className="mb-2 p-4 w-80 bg-[#F5F0E5] border-none rounded-lg text-[#A1824A] placeholder-[#A1824A]"
        type="password"
        placeholder="비밀번호 재입력"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
      />

      {/* Days Left Until Exam Dropdown */}
      <Menu as="div" className="relative inline-block text-left mb-2 w-80">
        <div>
          <MenuButton className="inline-flex w-full justify-between rounded-md bg-[#F5F0E5] px-3 py-4 text-base text-[#A1824A] shadow-sm hover:bg-gray-50">
            {daysUntilExam || '시험까지 몇 일 남았나요?'}
            <ChevronDownIcon aria-hidden="true" className="h-5 w-5 text-[#A1824A]" />
          </MenuButton>
        </div>
        <MenuItems className="absolute z-10 mt-2 w-full rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none max-h-48 overflow-y-auto">
          <div className="py-1">
            {[...Array(30).keys()].map((day) => (
              <MenuItem key={day + 1}>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                    } block w-full px-4 py-2 text-sm`}
                    onClick={() => setDaysUntilExam(day + 1)}
                  >
                    {day + 1} 일
                  </button>
                )}
              </MenuItem>
            ))}
          </div>
        </MenuItems>
      </Menu>

      {/* Purpose of Exam Dropdown */}
      <Menu as="div" className="relative inline-block text-left mb-2 w-80">
        <div>
          <MenuButton className="inline-flex w-full justify-between rounded-md bg-[#F5F0E5] px-3 py-4 text-base text-[#A1824A] shadow-sm hover:bg-gray-50">
            {examPurpose || '토익스피킹 응시목적은 무엇인가요?'}
            <ChevronDownIcon aria-hidden="true" className="h-5 w-5 text-[#A1824A]" />
          </MenuButton>
        </div>
        <MenuItems className="absolute z-10 mt-2 w-full rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <MenuItem>
              {({ active }) => (
                <button
                  className={`${
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                  } block w-full px-4 py-2 text-sm`}
                  onClick={() => setExamPurpose('승진')}
                >
                  승진
                </button>
              )}
            </MenuItem>
            <MenuItem>
              {({ active }) => (
                <button
                  className={`${
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                  } block w-full px-4 py-2 text-sm`}
                  onClick={() => setExamPurpose('취업')}
                >
                  취업
                </button>
              )}
            </MenuItem>
            <MenuItem>
              {({ active }) => (
                <button
                  className={`${
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                  } block w-full px-4 py-2 text-sm`}
                  onClick={() => setExamPurpose('재미로')}
                >
                  재미로
                </button>
              )}
            </MenuItem>
            <MenuItem>
              {({ active }) => (
                <button
                  className={`${
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                  } block w-full px-4 py-2 text-sm`}
                  onClick={() => setExamPurpose('영어공부')}
                >
                  영어공부
                </button>
              )}
            </MenuItem>
          </div>
        </MenuItems>
      </Menu>


      {/* Continue Button */}
      <button
        className="w-80 px-4 py-4 mt-4 bg-[#009963] text-white rounded-lg font-bold"
        onClick={handleSignUp}
      >
        토익스피킹 부수기
      </button>
    </div>
  );
};

export default SignUpPage;